import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BlockUi from 'react-block-ui';
import Loader from '../Loader/Loader';
import MemoVimeoPlayer from './components/VimeoPlayer';
import YoutubePlayer from './components/YoutubePlayer';
import './videoPlayer.scss';
import { isVideoDenied } from '../../../helpers/video-access.helper';

const VideoPlayer = ({
  video,
  autoplay = false,
  isBackground = false,
  hasControl = true,
  loop = false,
  muted = false,
  title = true,
}) => {
  const [blocking, setBlocking] = useState(false);
  const [hasError, setHasError] = useState(false);

  function isVimeoPlatform() {
    return video ? video.platform === 'vimeo' : false;
  }

  function isYoutubePlatform() {
    return video ? video.platform === 'youtube' : false;
  }

  function onPlayerReady() {
    setHasError(false);
    setBlocking(false);
  }

  function onPlayerError() {
    setHasError(true);
    setBlocking(false);
  }

  function initPlayerState() {
    if (!video) {
      return;
    }

    if (isBackground) {
      setBlocking(false);
      setHasError(false);
    } else if (isVideoDenied(video)) {
      setBlocking(false);
      setHasError(true);
    } else {
      setBlocking(true);
      setHasError(false);
    }
  }

  useEffect(() => {
    initPlayerState();
  }, [video]);

  return (
      <div className={isBackground ? 'bg-video-container' : 'video-container'}>
        {isBackground ? (
         <MemoVimeoPlayer
            video={video}
            isBackground={isBackground}
            autoplay={autoplay}
            onPlayerError={onPlayerError}
            onPlayerReady={onPlayerReady}
         />
        ) : (
          <BlockUi tag="div" blocking={blocking} loader={<Loader hasBackground={false} />}>
            {isVimeoPlatform() && !hasError ? (
              <div className="video">
                <MemoVimeoPlayer
                  onPlayerReady={onPlayerReady}
                  onPlayerError={onPlayerError}
                  video={video}
                  autoplay={autoplay}
                  isBackground={isBackground}
                  hasControl={hasControl}
                  loop={loop}
                  muted={muted}
                  title={title}
                />
              </div>
            ) : null}
            {isYoutubePlatform() ? (
              <div className="video">
                <YoutubePlayer
                  onPlayerReady={onPlayerReady}
                  onPlayerError={onPlayerError}
                  video={video}
                  autoplay={autoplay}
                  isBackground={isBackground}
                  hasControl={hasControl}
                  loop={loop}
                  muted={muted}
                  title={title}
                />
              </div>
            ) : null}
            {hasError ? (
              <div className="error-block">
                <p className="text-white text-center error p-3">
                  Oops! Video is unavailable. <br />
                  Please try again later.
                </p>
              </div>
            ) : null}
          </BlockUi>
        )}
      </div>
  );
};

VideoPlayer.propTypes = {
  video: PropTypes.object,
  autoplay: PropTypes.bool,
  isBackground: PropTypes.bool,
  hasControl: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  title: PropTypes.bool,
};

const MemoVideoPlayer = React.memo(VideoPlayer);

export default MemoVideoPlayer;
