import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Player from '@vimeo/player';
import { isVideoDenied } from '../../../../helpers/video-access.helper';

const hasWindow = typeof window !== 'undefined';

const VimeoPlayer = ({
  video,
  onPlayerReady,
  onPlayerError,
  autoplay = false,
  isBackground = false,
  hasControl = true,
  loop = false,
  muted = false,
  title = true,
}) => {
  const vimeoConfig = {
    autopause: !isBackground && !loop,
    autoplay: autoplay || isBackground,
    background: isBackground,
    byline: true,
    color: 'ff921a',
    controls: hasControl && !isBackground,
    dnt: false,
    loop: loop || isBackground,
    responsive: false,
    muted: muted || isBackground,
    playsinline: true,
    portrait: true,
    speed: false,
    title: title && !isBackground,
    transparent: true,
  };

  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (video) {
      onVideoChange();
    }
  }, [video]);

  function onVideoChange() {
    if (!hasWindow) {
      return;
    }

    if (!player) {
      return initPlayer();
    }

    if (player && !isPlayerReady) {
      return player.destroy().then(() => {
        if (videoRef && videoRef.current) {
          videoRef.current.removeAttribute('data-vimeo-initialized');
          videoRef.current.innerHTML = '';
        }
        setIsPlayerReady(false);
        setPlayer(null);

        initPlayer();
      });
    }

    if (player && isPlayerReady) {
      return loadVideo();
    }
  }

  function initPlayer() {
    if (isVideoDenied(video)) {
      return onPlayerError();
    }

    const config = getConfig(video);

    try {
      const vimeoPlayer = new Player(videoRef.current, config);
      setPlayer(vimeoPlayer);

      vimeoPlayer
        .ready()
        .then(() => {
          onPlayerReady();
          setIsPlayerReady(true);
        })
        .catch(err => {
          onPlayerError();
        });
    } catch (error) {
      onPlayerError();
      console.log(error);
    }
  }

  function loadVideo() {
    if (isVideoDenied(video) || !player) {
      return onPlayerError;
    }

    player
      .loadVideo(getConfig(video))
      .then(() => {
        onPlayerReady();
      })
      .catch(err => {
        onPlayerError();
        throw err;
      });
  }

  function getConfig(video) {
    if (video.videoId) {
      return { ...vimeoConfig, id: getVideoId(video) };
    }

    if (video.url) {
      return { ...vimeoConfig, url: video.url };
    }

    onPlayerError();

    throw new Error('Vimeo config should have id or url');
  }

  function getVideoId(video) {
    const prefix = '/videos/';

    if (!Number.isInteger(video.videoId) && video.videoId.includes(prefix)) {
      return Number(video.videoId.slice(prefix.length));
    }

    return Number(video.videoId);
  }

  function getBackgroundClass() {
    return isBackground ? 'background' : '';
  }

  return (
    hasWindow &&
    videoRef && <div ref={videoRef} className={`w-100 h-100 vimeo-player ${getBackgroundClass()}`} />
  );
};

VimeoPlayer.propTypes = {
  video: PropTypes.object,
  onPlayerReady: PropTypes.func,
  onPlayerError: PropTypes.func,
  autoplay: PropTypes.bool,
  isBackground: PropTypes.bool,
  hasControl: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  title: PropTypes.bool,
};

const MemoVimeoPlayer = React.memo(VimeoPlayer);

export default MemoVimeoPlayer;
