import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { isVideoDenied } from '../../../../helpers/video-access.helper';

const hasWindow = typeof window !== 'undefined';

const YoutubePlayer = ({ video, onPlayerReady, onPlayerError, autoplay = false, hasControl = true, loop = false }) => {
  const videoRef = useRef(null);
  const youtubeConfig = {
    autoplay: autoplay,
    controls: hasControl,
    disablekb: 0,
    enablejsapi: 1,
    fs: 1,
    iv_load_policy: 1,
    loop: loop,
    modestbranding: 1,
    origin: hasWindow ? window.location.origin : '/',
    playsinline: 1,
    rel: 0,
    host: 'https://www.youtube.com',
  };

  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (video) {
      onVideoChange();
    }
  }, [video]);

  function onVideoChange() {
    if (!player) {
      return initIframeApi();
    }

    if (player && !isPlayerReady) {
      destroyPlayer();
      return initIframeApi();
    }

    if (player && isPlayerReady) {
      return loadVideo();
    }
  }

  function initIframeApi() {
    if (!hasWindow) {
      return;
    }

    if (window.YT) {
      initPlayer();
      return;
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';

    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => initPlayer();
  }

  function initPlayer() {
    if (!hasWindow) {
      return;
    }

    if (isVideoDenied(video)) {
      return onPlayerError();
    }

    if (player) {
      destroyPlayer();
    }

    try {
      const youtubePlayer = new window.YT.Player(videoRef.current, {
        videoId: video.videoId,
        playerVars: youtubeConfig,
        events: {
          onStateChange: e => onStateChange(e),
          onError: e => onError(e),
          onReady: e => onReady(e),
        },
      });
      setPlayer(youtubePlayer);
    } catch (error) {
      onPlayerError();
      console.log(error);
    }
  }

  function onReady() {
    setIsPlayerReady(true);
    onPlayerReady();
  }

  function onStateChange() {
    onPlayerReady();
  }

  function onError() {
    onPlayerError();
  }

  function loadVideo() {
    if (isVideoDenied(video)) {
      return onPlayerError();
    }
    player.cueVideoById(video.videoId);
  }

  function destroyPlayer() {
    player.destroy();
    setPlayer(null);
    setIsPlayerReady(false);
  }

  return hasWindow && <div ref={videoRef} className="w-100 h-100" />;
};

YoutubePlayer.propTypes = {
  video: PropTypes.object,
  onPlayerReady: PropTypes.func,
  onPlayerError: PropTypes.func,
  autoplay: PropTypes.bool,
  hasControl: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
};

export default YoutubePlayer;
